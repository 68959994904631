export default {
  MuiPaper: {
    styleOverrides: {
      root: {
        '& .MuiDataGrid-filterForm': {
          gap: '8px',
        },
        '& .MuiDataGrid-columnsManagementHeader': {
          padding: '8px 18px 2px 18px',
        },
      },
    },
  },
};
