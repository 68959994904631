import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Divider, Tab } from '@mui/material';

import { Page, PageHeader } from '@/components';
import { Tabs } from '@/components/Tabs';

import { MasterIndexTabs } from './data';
import { LocalStorageItems } from '@/constants';
import { useUserStore } from '@/store';

export const MasterIndex: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const { userAgencyId } = useUserStore();

  useEffect(() => {
    if (userAgencyId) {
      localStorage.setItem(LocalStorageItems.CurrentAgencyId, userAgencyId);
    }
  }, [userAgencyId]);

  // Hide the tabs if there are more than 3 paths in the URL.
  // We have children routes that does not need tabs.
  const showTabs = paths.length < 4;

  return (
    <Page title="Master Index">
      {showTabs && <PageHeader title="Master Index" />}
      {showTabs && (
        <>
          <Tabs
            value={paths[2]}
            onChange={(_e, newValue: string) => navigate(newValue)}
          >
            {MasterIndexTabs.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
          <Divider />
        </>
      )}
      <Outlet />
    </Page>
  );
};
