import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { IUser, Role } from '@/models';
import { isTargetRole } from '@/services';

const useShowIncomingCalls = (user: IUser | null) => {
  const { pathname } = useLocation();
  const excludedPaths = /^\/(cases|master-index|tasks|agencies)(\/[^/]+)*$/;

  const isExcludedPage = useMemo(() => {
    return excludedPaths.test(pathname);
  }, [pathname]);

  const showIncomingCalls = useMemo(() => {
    if (!user) return;

    return (
      !isExcludedPage &&
      (isTargetRole(user, Role.ACCOUNT_ADMIN) ||
        isTargetRole(user, Role.DISPATCHER))
    );
  }, [isExcludedPage, user]);

  return showIncomingCalls;
};

export default useShowIncomingCalls;
