import { GridFilterModel } from '@mui/x-data-grid-pro';

import { LocalStoragePrefix } from '@/constants';
import { ICFSLog, IKaseLog, IPenalCode } from '@/models';

interface LocalStorageGetParams {
  prefix?: LocalStoragePrefix | string;
  key: string;
  shouldParse?: boolean;
}

interface LocalStorageSetParams {
  prefix?: LocalStoragePrefix | string;
  key: string;
  value: any;
}

export const getLocalStorageKey = (
  key: string,
  prefix?: LocalStoragePrefix | string,
) => {
  if (!prefix) return key;
  return `${prefix}-${key}`;
};

export const setLocalStorageItem = ({
  prefix,
  key,
  value,
}: LocalStorageSetParams) => {
  const storageKey = getLocalStorageKey(key, prefix);
  if (typeof value === 'object') {
    localStorage.setItem(storageKey, JSON.stringify(value));
  } else {
    localStorage.setItem(storageKey, value);
  }
};

export const getLocalStorageItem = ({
  prefix,
  key,
  shouldParse,
}: LocalStorageGetParams) => {
  const storageKey = getLocalStorageKey(key, prefix);
  const item = localStorage.getItem(storageKey);
  return shouldParse && item ? JSON.parse(item || '') : item;
};

export const getPenalCodeLabel = (penalCode: IPenalCode) => {
  const firstPart = [
    penalCode.lawTitle,
    penalCode.section,
    penalCode.subsection,
    penalCode.statutoryClass,
    penalCode.category,
    penalCode.degree,
  ]
    .filter((item) => !!item)
    .join(' ');
  return [firstPart, penalCode.miniLawDescription]
    .filter((item) => !!item)
    .join(' - ');
};

export const createUrlWithParams = (
  _baseURL: string,
  params: Record<string, any>,
) => {
  const url = new URL(_baseURL);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );
  return url.toString();
};

export const filterLogs = <T extends IKaseLog | ICFSLog>(
  logs: T[],
  searchTerm: string,
): T[] => {
  if (!searchTerm) return logs;

  const lowercasedTerm = searchTerm.toLowerCase();

  return logs.filter((log) => {
    const matchesEvent = log.event.toLowerCase().includes(lowercasedTerm);
    const matchesCreatedBy =
      log.createdBy?.firstName.toLowerCase().includes(lowercasedTerm) ||
      log.createdBy?.lastName.toLowerCase().includes(lowercasedTerm);
    const matchesData =
      log.data &&
      JSON.stringify(log.data?.new || '')
        .toLowerCase()
        .includes(lowercasedTerm);

    return matchesEvent || matchesCreatedBy || matchesData;
  });
};

export const getKeyByValue = (
  mapName: Map<any, any>,
  searchValue: any,
): any | undefined => {
  for (const [key, value] of mapName.entries()) {
    if (value === searchValue) {
      return key;
    }
  }
  return undefined;
};

export const preventSubmit = (event: {
  key: string;
  preventDefault: () => void;
  stopPropagation: () => void;
}) => {
  if (event?.key === 'Enter') {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
};

export const getKeysByValues = (
  values: string[],
  type: { [key: string]: string },
) => {
  const keys = values.map((value: string) => {
    const entry = Object.entries(type).find(([, val]) => val === value);
    return entry ? entry[0] : null;
  });

  return keys;
};

export const createItem = async (
  apiMethod: (data: { name: string }) => Promise<{ data: { _id: string } }>,
  setValue: (name: string, value: any) => void,
  setDependency: (value: string | undefined) => void,
  itemName: string,
  propertyName: string,
) => {
  try {
    const res = await apiMethod({ name: itemName });
    setValue(propertyName, res.data._id);
    setDependency(res.data._id);
  } catch (e: any) {
    setDependency(undefined);
    throw new Error(e.message);
  }
};

export const createItemType = async (
  apiMethod: (data: { type: string }) => Promise<{
    data: {
      _id: string;
      type: string;
    };
  }>,
  setValue: (name: string, value: any) => void,
  setDependency: (value: string | undefined) => void,
  itemType: string,
  propertyName: string,
) => {
  try {
    const res = await apiMethod({ type: itemType });
    setValue(propertyName, res.data.type);
    setDependency(res.data._id);
  } catch (e: any) {
    setDependency(undefined);
    throw new Error(e.message);
  }
};

export const createItemModel = async (
  apiMethod: (
    itemUrlId: string,
    model: string,
  ) => Promise<{ data: { _id: string; name: string } }>,
  setValue: (name: string, value: any) => void,
  setDependency: (value: string | undefined) => void,
  modelValue: string,
  propertyName: string,
  itemUrlId: string,
) => {
  try {
    const res = await apiMethod(itemUrlId, modelValue);
    setValue(propertyName, res.data.name);
    setDependency(res.data._id);
  } catch (e: any) {
    setDependency(undefined);
    throw new Error(e.message);
  }
};

export const handlePrint = (selector: string) => {
  window.onafterprint = () => {
    window.location.reload();
  };

  const printContents = document.querySelector(selector)?.innerHTML;

  if (printContents) {
    document.body.innerHTML = printContents;
    window.print();
  }
};

export const formatNarrativeContent = (blocks: { text: string }[]) => {
  return blocks.map((block: any) => block.text).join('');
};

export const mapFilterToQueryParam = (model: GridFilterModel) => {
  const { items, logicOperator } = model;

  const conditions = items
    .map(({ field, operator, value }) => {
      if (value === undefined || value === null || value === '') return null;
      switch (operator) {
        case 'contains':
          return { [field]: { $regex: value, $options: 'i' } };
        case 'startsWith':
          return { [field]: { $regex: `^${value}`, $options: 'i' } };
        case 'endsWith':
          return { [field]: { $regex: `${value}$`, $options: 'i' } };
        case 'doesNotContain':
          return { [field]: { $not: { $regex: value, $options: 'i' } } };
        case 'equals':
          return { [field]: { $eq: value } };
        case 'doesNotEqual':
          return { [field]: { $ne: value } };
        case 'isEmpty':
          return { [field]: { $in: [null, ''] } };
        case 'isNotEmpty':
          return { [field]: { $nin: [null, ''] } };
        case 'isAnyOf':
          return { [field]: { $in: Array.isArray(value) ? value : [value] } };
        default:
          throw new Error(`Unsupported operator: ${operator}`);
      }
    })
    .filter(Boolean);

  if (conditions.length === 0) return undefined;

  return JSON.stringify(
    logicOperator === 'or' ? { $or: conditions } : { $and: conditions },
  );
};
