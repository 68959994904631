import {
  FC,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  FilterOptionsState,
  FormHelperText,
  InputBaseProps,
  InputLabel,
  Typography,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { IcArrowDown, IcClose, IcPlusCircle } from '@/assets/images';

import { NoResultItem } from './NoResultItem';
import { AutoCompleteRoot, TextField } from './styles';
import { Chip } from '../../Chip';
import { BaseFieldProps } from '../types';

interface OptionType {
  inputValue?: string;
  label: string | ReactElement;
  value: string | number;
  group?: string;
}
const filter = createFilterOptions<string | OptionType>();

export interface BaseAutocompleteProps
  extends Omit<InputBaseProps, 'value' | 'error' | 'onChange' | 'onKeyDown'>,
    BaseFieldProps {
  value: string | string[];
  defaultValue?: OptionType;
  multiple?: boolean;
  limitTags?: number;
  items?: OptionType[];
  showPopupIcon?: true | false | 'auto';
  onChange?: (...event: any[]) => void;
  onInputChange?: (name: string) => void;
  groupBy?: (option: any) => string;
  clearOnBlur?: boolean;
  freeSolo?: boolean;
  addNew?: boolean;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLDivElement> & {
      defaultMuiPrevented?: boolean;
    },
  ) => void;
  onCreate?: (newValue: string) => void;
  disableClearable?: boolean;
}

export const BaseAutocomplete: FC<BaseAutocompleteProps> = ({
  value,
  label,
  multiple,
  limitTags,
  items = [],
  onChange,
  onInputChange,
  error,
  disabled = false,
  groupBy,
  placeholder,
  showPopupIcon,
  sx,
  clearOnBlur = true,
  freeSolo = false,
  addNew = true,
  onKeyDown,
  onCreate,
  disableClearable,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const isIncludingGroupOption = useMemo(() => {
    return (
      !!items.length &&
      typeof items[0] !== 'string' &&
      items.findIndex(({ group }) => group) !== -1
    );
  }, [items]);

  const processedItems = useMemo(() => {
    if (!isIncludingGroupOption) {
      return items;
    }

    const newItems = [];
    let group = '';
    for (const item of items) {
      if (item.group) {
        group = item.group;
      } else {
        newItems.push({
          ...item,
          group,
        });
      }
    }
    return newItems;
  }, [items]);

  const renderOption = (props: any, option: string | OptionType) => {
    let listText: string | ReactElement;

    if (typeof option === 'string') {
      listText = option;
    } else if (typeof option.label === 'string') {
      listText = option.label;
    } else {
      // If it's a ReactElement (like our "Create new" option), render it directly
      listText = option.label;
    }

    return (
      <li
        {...props}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block',
        }}
        title={typeof listText === 'string' ? listText : ''}
      >
        {listText}
      </li>
    );
  };

  const groupByGroupOption = (option: any) => option.group;

  const handleChange = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      newValue: string | OptionType | (string | OptionType)[] | null,
    ) => {
      if (onChange) {
        if (typeof newValue === 'string') {
          onChange(newValue);
        } else if (!Array.isArray(newValue)) {
          if (
            typeof newValue === 'object' &&
            newValue !== null &&
            'inputValue' in newValue
          ) {
            if (onCreate && newValue.inputValue) {
              onCreate(newValue.inputValue);
            }
            onChange(newValue.value);
          } else {
            onChange(newValue?.value);
          }
        } else {
          const valueArr = newValue.map((v) =>
            typeof v === 'string' ? v : v.value,
          );
          onChange(valueArr);
        }
      }
    },
    [onChange, onCreate],
  );

  const getValue = useMemo(() => {
    if (typeof value === 'string' || typeof value === 'number') {
      return items?.find((option) => option.value === value) || '';
    }
    if (Array.isArray(value)) {
      const values: OptionType[] = [];
      value.map((item) => {
        const optionValue = items.find((option) => option.value === item);
        if (optionValue) values.push(optionValue);
      });
      return values;
    }
    return multiple ? [] : '';
  }, [value, items]);

  const getOptionLabel = useCallback((option: string | OptionType) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.label as string;
  }, []);

  const handleInputChange = useCallback(
    (_: SyntheticEvent<Element, Event>, name: string) => {
      setInputValue(name);
      if (onInputChange) {
        onInputChange(name);
      }
    },
    [onInputChange],
  );

  const filterOptions = useCallback(
    (
      options: (string | OptionType)[],
      params: FilterOptionsState<string | OptionType>,
    ) => {
      const filtered = filter(options, params);
      const { inputValue: newInputValue } = params;

      if (
        freeSolo &&
        newInputValue !== '' &&
        addNew &&
        Array.isArray(options) &&
        !options.some((option) => {
          if (typeof option === 'string') {
            return false;
          }
          return option.label === newInputValue;
        })
      ) {
        filtered.push({
          inputValue: newInputValue,
          label: (
            <Typography
              color="error"
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <IcPlusCircle style={{ marginRight: 5, marginTop: '2px' }} />
              Create{' '}
              <Typography sx={{ fontWeight: 600, mx: 0.5 }}>
                {newInputValue}{' '}
              </Typography>{' '}
              as a new item
            </Typography>
          ),
          value: newInputValue,
        });
      }

      return filtered;
    },
    [freeSolo],
  );

  return (
    <AutoCompleteRoot sx={sx}>
      {!!label && <InputLabel>{label}</InputLabel>}
      <Autocomplete
        autoHighlight
        value={getValue}
        disableClearable={disableClearable || !getValue}
        disabled={disabled}
        inputValue={inputValue}
        onChange={handleChange}
        groupBy={isIncludingGroupOption ? groupByGroupOption : groupBy}
        onInputChange={handleInputChange}
        options={processedItems}
        getOptionLabel={getOptionLabel}
        noOptionsText={<NoResultItem />}
        renderOption={renderOption}
        renderTags={(values: (string | OptionType)[], getTagProps) =>
          values.map((option: string | OptionType, index: number) => (
            <Chip
              variant="filled"
              color="error"
              label={typeof option === 'string' ? option : option.label}
              {...getTagProps({ index })}
              key={index}
              deleteIcon={<IcClose color="white" />}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        forcePopupIcon={showPopupIcon}
        popupIcon={<IcArrowDown />}
        multiple={multiple}
        limitTags={limitTags}
        handleHomeEndKeys
        filterSelectedOptions
        selectOnFocus
        clearOnBlur={clearOnBlur}
        onKeyDown={onKeyDown}
        freeSolo={freeSolo}
        filterOptions={filterOptions}
      />
      <FormHelperText error>{error}</FormHelperText>
    </AutoCompleteRoot>
  );
};
