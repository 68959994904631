import { useRef } from 'react';

import {
  styled,
  TableContainer as MuiTableContainer,
  TableContainerProps,
} from '@mui/material';

import { useDimensions } from '@/hooks';
import { colors } from '@/theme/variables';

export interface TableColumn {
  key: string;
  label: string;
  parser?: (val: string) => string;
  width?: number;
}

export interface TableMenuState {
  menuEl: HTMLElement | null;
  selectedId?: string;
}

export const TableContainerRoot = styled(MuiTableContainer)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.mode !== 'light' ? colors.grey[80] : 'white',
  borderRadius: 8,
  boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1)',
  padding: '0px 10px',
  paddingBottom: 20,
  '& table': {
    // height: '100%',
    borderCollapse: 'collapse',
    '& tr': {
      height: 37,
      '&:hover': {
        backgroundColor: colors.lightGrey,
      },
    },
    '& td': {
      whiteSpace: 'nowrap',
      color: theme.palette.mode !== 'light' ? colors.grey[30] : colors.grey[80],
      lineHeight: '1.5rem',
    },
  },
  '& thead': {
    '& th': {
      backgroundColor:
        theme.palette.mode !== 'light' ? colors.grey[80] : 'white',
      color: theme.palette.mode !== 'light' ? 'white' : colors.grey[100],
    },
  },
  '& .MuiTableRow-root': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    borderBottom:
      theme.palette.mode !== 'light'
        ? `1px solid ${colors.grey[60]}`
        : `1px solid ${colors.grey[10]}`,
  },
  '& tbody': {
    '& .MuiTableRow-root:last-child': {
      borderBottomWidth: 0,
    },
  },
}));

export const TableContainer = (
  props: TableContainerProps & {
    dontCalculateHeight?: boolean;
    fitContent?: boolean;
    overflow?: string;
  },
) => {
  const tableContainerRootRef = useRef(null);
  const { height } = useDimensions(tableContainerRootRef);
  const { dontCalculateHeight, fitContent, overflow, ...rest } = props;
  return (
    <div
      style={{
        flex: 1,
        marginTop: 15,
        width: fitContent ? '100%' : undefined,
        overflow,
      }}
      ref={tableContainerRootRef}
    >
      <TableContainerRoot
        {...rest}
        style={{
          ...(!dontCalculateHeight && { maxHeight: height, height }),
        }}
      />
    </div>
  );
};
